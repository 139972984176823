import request from '@/utils/request'

// 任务列表 taskList
// 当日进线:today_thread;当日待访:today_to_visit;当日回访:today_visit;过期未访:expired_visit;
// 视频客户:video;未购客户:no_buy;已购客户:buy;在用客户:use;停用客户:stop;预停客户：pre_stop;
export function getTaskList(params) {
  return request({
    url: '/admin/jobList',
    method: 'GET',
    params
  })
}
export function assignLog(id,params) {
  return request({
    url: 'admin/assignLog/'+id,
    method: 'GET',
    params
  })
}
// 添加档案
export function postAddCustomerRecord(data) {
  return request({
    url: '/admin/createContract',
    method: 'POST',
    data
  })
}

// 咨询产品
export function getGoods(params) {
  return request({
    url: '/admin/getGoods',
    method: 'GET',
    params
  })
}

// 档案详情-客户跟进
export function getCustomerRecordDetail(id) {
  return request({
    url: `/admin/contractFllow/${id}`,
    method: 'GET',
  })
}

// 客户级别
export function getCustomerLevel(params) {
  return request({
    url: '/admin/contractVisit/levelOptions',
    method: 'GET',
    params,
  })
}

// 档案详情客户订单
export function getCustomerOrderList(id) {
  return request({
    url: `/admin/contractOrder/${id}`,
    method: 'GET',
  })
}

// 档案详情订单详情
export function getCustomerOrderDetail(params) {
  return request({
    url: '/admin/contractOrderDetail',
    method: 'GET',
    params,
  })
}

// 档案详情, 档案详情
export function getSeeCustomerRecordDetail(id) {
  return request({
    url: `/admin/contractM/detail/${id}`,
    method: 'GET',
  })
}

// 添加跟进
export function postAddFollow(id, data) {
  return request({
    url: `/admin/contractVisit/${id}`,
    method: 'POST',
    data
  })
}

// 添加客户跟进, 修改客户信息合并
export function postEditCustomerDetail(id, data) {
  return request({
    url: `/admin/saveContractFllow/${id}`,
    method: 'POST',
    data
  })
}

// 用户标签公共
export function getCustomerTag() {
  return request({
    url: `/admin/memberTag`,
    method: 'GET',
  })
}

// 修改客户档案
export function putEditCustomerDetail(id, data) {
  return request({
    url: `/admin/updateContract/${id}`,
    method: 'PUT',
    data
  })
}

// 档案详情-客户问诊
export function getDialogRecord(id) {
  return request({
    url: `/admin/contractDiag/${id}`,
    method: 'GET',
  })
}

// 订单详情
export function getOrderDetail(params) {
  return request({
      url: `/admin/contractOrderDetail`,
    method: 'GET',
    params
  })
}

// 拨打电话
export function postCallMobile(id, data) {
  return request({
    url: `/admin/callPhone/${id}`,
    method: 'POST',
    data
  })
}

// 通话记录列表
export function getCallRecordList(params) {
  return request({
    url: `/admin/callRecordList`,
    method: 'GET',
    params
  })
}

// 工作任务统计
export function getJobCount(params) {
  return request({
    url: `/admin/jobCount`,
    method: 'GET',
    params
  })
}

// 档案统计
export function getRecordReport() {
  return request({
    url: `/admin/stat/contractStat`,
    method: 'GET',
  })
}

// 销售日报
export function getTodaySellReport(params) {
  return request({
    url: `/admin/stat/saleStat`,
    method: 'GET',
    params
  })
}

// 进线统计
export function getTodayBranchingReport(params) {
  return request({
    url: `/admin/stat/threadStat`,
    method: 'GET',
    params
  })
}

// 媒体日报
export function getTodayMediaReport(params) {
  return request({
    url: `/admin/stat/mediaStat`,
    method: 'GET',
    params
  })
}

// 复购占比
export function getRepurchaseRate(params) {
  return request({
    url: `/admin/stat/againRateStat`,
    method: 'GET',
    params
  })
}

// 消耗签收
export function getConsumeSign(params) {
  return request({
    url: `/admin/stat/costStat`,
    method: 'GET',
    params
  })
}

// 分机情况
export function getPhoneStatus(params) {
  return request({
    url: `/admin/callExtension`,
    method: 'GET',
    params
  })
}

// 下载通话记录
export function getDownloadCall(id) {
  return request({
    url: `/admin/getDownloadCall/${id}`,
    method: 'GET',
  })
}

// 物流跟进
export function getLogisticsFollow(params) {
  return request({
    url: `/admin/logisticsFollow`,
    method: 'GET',
    params
  })
}

// 客户级别 筛选
export function getVisitLevel(params) {
  return request({
    url: `/admin/contractVisit/levelOptions`,
    method: 'GET',
    params
  })
}

// 客户级别所有
export function getAllVisitLevel(params) {
  return request({
    url: `/admin/common/visitLevel`,
    method: 'GET',
    params
  })
}

// 订单统计
export function getOrderStatusList(params) {
  return request({
    url: `/admin/orderStat`,
    method: 'GET',
    params
  })
}

// 订单列表
export function getOrderCustomerList(params) {
  return request({
    url: `/admin/cdmOrderList`,
    method: 'GET',
    params
  })
}

// 修改订单
export function postEditOrder(data) {
  return request({
    url: `admin/editCdmOrder`,
    method: 'POST',
    data
  })
}

//批量审批
export function postBatchCheckOrder(data) {
  return request({
    url: `/admin/examineCdmOrder`,
    method: 'POST',
    data
  })
}

// 导出订单 admin/orderStat
export function postExportOrder(params) {
  return request({
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'blob',
    url: `/admin/exportOrder`,
    method: 'get',
    params
  })
}



// 字段表信息列表
export function getAdminFieldIndex(params) {
  return request({
    url: 'admin/field/index',
    method: 'GET',
    params
  })
}

// 更新字段表信息
export function putAdminFieldIndex(data) {
  return request({
    url: 'admin/field/save',
    method: 'PUT',
    data
  })
}

// 渠道来源/ from 投放来源
export function getChancelFrom(params) {
  return request({
    url: 'admin/basicConfig',
    method: 'GET',
    params
  })
}

// 获取客服列表
export function getStaffList(params) {
  return request({
    url: '/admin/common/getStaff',
    method: 'GET',
    params
  })
}

// 是否能够查看手机号
export function postExamineShowMobileStatus(id, data) {
  return request({
    url: `admin/setStaffMobileHidden/${id}`,
    method: 'POST',
    data
  })
}

// 分配线索记录
export function getAssignThreadRecordList(id, params) {
  return request({
    url: `admin/assignLog/${id}`,
    method: 'GET',
    params
  })
}

// 线索分配日志
export function getThreadAssignLogsList(params) {
  return request({
    url: `admin/threadAssignLogs`,
    method: 'GET',
    params
  })
}

// 登录日志
export function getLoginLogList(params) {
  return request({
    url: `admin/loginLog`,
    method: 'GET',
    params
  })
}

// 登录日志
export function getRepeatLineLogList(params) {
  return request({
    url: `admin/repeatThreadLog`,
    method: 'GET',
    params
  })
}

export function getDiagChatsApi(params) {
  return request({
    url: `admin/getDiagChats`,
    method: 'GET',
    params
  })
}

